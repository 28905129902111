import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Apple Watch Templates Now Include a Sketch Version",
  "date": "2014-12-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You’ve read that right, `}<a parentName="p" {...{
        "href": "https://twitter.com/rafahari/status/543333621420261376"
      }}>{`Apple recently updated the Apple Watch design resources to include Sketch files`}</a>{` (alongside the Photoshop version). It’s impressive how an app by such a small team (compared to Adobe) could rise so quickly to being the gold standard for UI design. It really goes to show how great of a piece of software it is`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. I also feel like we’re at a turning point. Unless Adobe does a complete 180 on its product stategy and focuses on what customers really want, the ship has sailed. Photoshop will just keep loosing relevance amongst designers.`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.apple.com/watchkit/"
      }}>{`You can download the Apple Watch design ressources here.`}</a></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Plus, I’m sure Apple really likes the fact that it’s only available for OS X.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      